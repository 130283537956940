@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);


.n-info {
  font-family: "Exo 2", sans-serif;
}

.n-info h4 {
  border-bottom: 1px solid #ddd;
  text-align: center;
  padding-bottom: 5px;
}

.n-info p {
  text-align: justify;
  font-size: 13px;
}

.n-details {
  border-top: 1px solid #ddd;
  font-family: "Exo 2", sans-serif;
  /* padding-top: 7px; */
}

.cardHeader .MuiTypography-h5 {
  font-size: 1.2rem !important;
}

